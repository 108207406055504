
.iconf {
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #014d8f;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
}

.iconf:hover {
    background-color: #014d8f;
    color: white;
}

ul {
    list-style-type:  square !important   ;
}

.iconcol {
    color: #014d8f;
}

.bt {
    border: none;
    background-color:#014d8f;
    color: white;
}

.bt:hover {
    background-color: #014d8f;
    color: white;
}

.peracol {
    background-color: rgba(245, 245, 245, 0.893);
    z-index: 1;
}

.bo {
    background-color: rgba(231, 231, 231, 0.893);

}

.hovtext {
    transition: all 0.3s;
    cursor: pointer;
}

.hovtext:hover {
    color: #014d8f;
}

.v {
    cursor: pointer;
    color: #014d8f;
}

.v:hover {
    color: #014d8f;
}