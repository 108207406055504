:root {
  --main-bg-color: #333;
  --overlay-color: rgba(1, 76, 143, 0.6);
  --color-blue: #014c8f;
  --color-white: #fff;
  --BACKGROUNDCOLOR: #e9f2f7;
  --BACKGROUNDCOLOR-BLUE: #014c8f;

}

.fade-in-up {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.bg-image,
.fade-in-up-target {
  opacity: 0;
  transform: translateY(20px);
}


/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.icon {
  font-size: 45px;
  color: var(--color-blue);
}

.h1-bo::after {
  content: "";
  width: 100px;
  border: 2px solid var(--color-blue);
  margin: 0 auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition: color 0.3s;
}

.hvr-sweep-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-blue);
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition: transform 0.3s ease-out;
  border-radius: 15px;
}

.hvr-sweep-to-top:hover,
.hvr-sweep-to-top:focus,
.hvr-sweep-to-top:active {
  color: var(--color-white);
}

.hvr-sweep-to-top:hover:before,
.hvr-sweep-to-top:focus:before,
.hvr-sweep-to-top:active:before {
  transform: scaleY(1);
}

.bie {
  color: var(--color-blue);
}

/* Services section */
.h2-bo {
  transition: all 3s;
  cursor: pointer;
}

.h2-bo:hover {
  color: var(--color-blue);
}

.h2-bo::after,
.h3-bo::after {
  content: "";
  width: 70px;
  border: 2px solid var(--color-blue);
  margin: 0 auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.size-f {
  color: gray;
  font-weight: 700;
}

.size-we {
  color: gray;
  font-weight: 400;
}

.size-blue {
  color: var(--color-blue);
  font-weight: 700;
  font-size: 20px;
}


.p-aboutus {
  content: "• ";
}


.context {
  width: 100%;
}

.context h1 {
  text-align: center;
  color:var(--color-blue);
  font-size: 50px;
}
.area {
  position: relative; /* Ensure it's relative for absolute positioning inside it */
  background: #ffffff;
  width: 100%;
}


.symbols {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.symbols li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  color: rgba(11, 63, 218, 0.2);
  font-size: 20px;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.symbols li::before {
  content: '₹';
}

.symbols li:nth-child(1) {
  left: 25%;
  font-size: 80px;
  animation-delay: 0s;
}

.symbols li:nth-child(2) {
  left: 10%;
  font-size: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.symbols li:nth-child(3) {
  left: 70%;
  font-size: 20px;
  animation-delay: 4s;
}

.symbols li:nth-child(4) {
  left: 40%;
  font-size: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.symbols li:nth-child(5) {
  left: 65%;
  font-size: 20px;
  animation-delay: 0s;
}

.symbols li:nth-child(6) {
  left: 75%;
  font-size: 110px;
  animation-delay: 3s;
}

.symbols li:nth-child(7) {
  left: 35%;
  font-size: 150px;
  animation-delay: 7s;
}

.symbols li:nth-child(8) {
  left: 50%;
  font-size: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.symbols li:nth-child(9) {
  left: 20%;
  font-size: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.symbols li:nth-child(10) {
  left: 85%;
  font-size: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}