:root {
  --main-bg-color: #333;
  --overlay-color: rgba(1, 76, 143, 0.6);
  --color-blue: #014c8f;
  --color-white: #fff;
  --BACKGROUNDCOLOR: #e9f2f7;
  --BACKGROUNDCOLOR-BLUE: #014c8f;

}

.color {
  color: var(--color-blue) !important;
}

.text-style {
  font-weight: 500;
  color: gray;
}

@keyframes img {
  0% {
    top: 0;
  }

  50% {
    top: 5%;
  }

  100% {
    top: 0%;
  }
}

.pera {
  height: 490px;
  position: relative;
}

/* Media query for extra-small screens */
@media (max-width: 575.98px) {
  .pera {
    margin-top: 35px !important;
    height: 310px !important;
  }
}

@media (max-width: 991px) {
  .pera {
    margin-top: 0px !important;
    height: 470px !important;
  }
}

/* Default styling for the image */
.imghome {
  max-width: 80%;

}

/* Adjust image size for tablets */
@media (max-width: 991px) {
  .imghome {
    margin-top: 0px !important;
    max-width: 70%;

  }
}

/* Additional adjustments for extra-small devices (phones) */
@media (max-width: 576px) {
  .imghome {
    max-width: 100%;

  }
}

/* Ensure the parent container does not overflow */
.img-animation {
  display: flex;
  justify-content: center;
  /* Center the image horizontally */
  /* overflow: hidden; */
  /* Prevent overflow */
  padding: 0;
  /* Remove padding if necessary */
}



#child {
  width: 100%;
  background-repeat: no-repeat;
  animation-name: img;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  position: absolute;
}

.we {
  font-size: 60px;
  font-weight: 800;
}

.col-text {
  color: var(--color-blue);
}

.f-size {
  font-size: 19px;
  font-weight: 500;
  color: gray;
}

.btn-all {
  background-color: var(--BACKGROUNDCOLOR-BLUE);
  border: none;
}

.section {
  background-color: var(--BACKGROUNDCOLOR);
  padding: 60px 20px;
  text-align: center;
}

/* Media query for extra-small screens (xs) */
@media (max-width: 575.98px) {
  .section {
    padding: 30px 0px;
  }
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
}


/* section 3 */
/* Default styles for larger screens */
.business-verticals {
  text-align: center;
  padding: 60px;
  background: var(--color-blue);
  color: var(--color-white);
  font-family: Arial, sans-serif;
}

/* Media query for small screens (xs and sm) */
@media (max-width: 575.98px) {

  /* Extra-small screens */
  .business-verticals {
    padding: 10px;

  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

  /* Small screens */
  .business-verticals {
    padding: 30px;
  }
}

.vertical {
  display: flex;
  align-items: stretch;
}

.verticalsub {
  background: var(--color-white);
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: var(--color-blue);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;
}

.verticalsub:hover {
  transform: scale(1.020);
}

.verticalsub img {
  padding: 1rem;
  /* Default padding */
}

@media (max-width: 576px) {

  /* xs breakpoint */
  .verticalsub img {
    margin-bottom: 25px !important;
    padding: 0 !important;
  }
}

.vertical i {
  font-size: 40px;
  margin-bottom: 10px;
}

.h1-sec-3 {
  margin-bottom: 40px;
  font-size: 2.4em;
}

@media (max-width: 576px) {
  /* xs breakpoint */

  .h1-sec-3 {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 2.1em;
  }
}

.h2-sec-3 {
  margin-bottom: 10px;
  font-weight: bold;
}

.p {
  font-weight: 500;
  color: gray;
}

.iconcolor {
  font-size: 80px !important;
  color: var(--color-blue);
}

.colorofother {
  color: var(--color-blue);

}






.scal {
  transition: all 0.5s ease-in-out;
  background-color: var(--color-white);
  height: 150px;
}

@media (max-width: 576px) {

  /* xs breakpoint */
  .scal {
    padding: 35px !important;
    height: 120px;
    /* Adjust height for xs screens */
    width: 120px;
    margin-left: -10px;
    width: 220px;
  }
}

.scal:hover {
  transform: scale(1.2);
}

.bg-scal {
  background-color: #e9f2f7;
}

.h6-hedding {
  font-size: 15px;
  font-weight: bolder;
  color: #014d8f8e;
}
.custom-padding {
  margin-top: 25px;
}

/* @media (max-width: 320px) {
  .custom-padding {
    margin-left: -10px !important;
  }
}
@media (min-width: 375px) and (max-width: 476px) {
  .custom-padding {
    margin-left: 20px !important;
  }
}

@media (min-width: 425px) and (max-width: 476px) {
  .custom-padding {
    margin-left: 45px !important;
  }
} */
