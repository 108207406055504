:root {
  --main-bg-color: #333;
  --overlay-color: rgba(1, 76, 143, 0.6);
  --color-blue: #014c8f;
  --color-white: #fff;
  --BACKGROUNDCOLOR: #e9f2f7;
  --BACKGROUNDCOLOR-BLUE: #014c8f;
  --BACKGROUNDBLUE-LIGHT: #014d8f2a;
  --color-light: #014d8f8f;
}

.servicebg {
  background-color: var(--BACKGROUNDCOLOR);
}

.ourservice {
  display: flex;
  align-items: center;
}

.color-service {
  color: var(--color-blue);
  font-size: 65px;
}

.color {
  color: var(--color-blue);
}

.main {
  border-left: 5px solid #ffffff;
  padding: 15px;
}

.main:hover {
  border-left: 5px solid #c0c0c0;
}

.main.active {
  border-left: 5px solid var(--color-blue) !important;
  background-color: var(--BACKGROUNDBLUE-LIGHT) !important;
}

.h3-col,
.main.active .fw-bolder {
  color: var(--color-blue);
}

.text-style-2 {
  font-weight: 500;
  color: gray;
}


.text-style-3 {
  padding-top: 5px;
  font-weight: 500;
  color: var(--color-light);
}





/* Add this CSS to your stylesheet */
.two-column-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.two-column-list li {
  width: 100%; /* Default to single column */
  list-style-type: none;
  padding: 5px;
  padding-left: 0px; /* Add padding for space around each item */
}

.two-column-list li::before {
  content: "• ";
}

@media (min-width: 992px) { 
  .two-column-list li {
    width: calc(50% - 10px);
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease forwards;
}


/* Default padding for larger screens */
.serviceopt{
  padding: 16px; /* Equivalent to p-4 */
  padding-bottom: 30px; /* Equivalent to pb-5 */
}

/* Media query for extra-small screens (xs) and small screens (sm) */
@media (max-width: 767.98px) {
  .serviceopt{
    padding: 5px;
    padding-bottom: 30px;
  }
}



