@keyframes fadeInScaleUp {
    from {
        opacity: 0;
        transform: scale(0.5); /* Start smaller */
    }
    to {
        opacity: 1;
        transform: scale(1); /* End at normal size */
    }
}

.img-animation {
    animation: fadeInScaleUp 2s ease-in-out;
}

.bgcolor{
    background-color:#014d8f ;
    color: white !important;
  }
  