:root {
    --main-bg-color: #333;
    --overlay-color: rgba(1, 76, 143, 0.6);
    --color-blue: #014c8f;
    --color-white: #fff;
    --BACKGROUNDCOLOR: #e9f2f7;
    --BACKGROUNDCOLOR-BLUE: #014c8f;

}


.bgcol {
    background-color: #4e909206;
}

.coly {
    border: none;
    background-color: var(--BACKGROUNDCOLOR-BLUE);
    color: var(--color-white);
    transition: all 0.5s;
}

.coly:hover {
    background-color: var(--main-bg-color);
    color: var(--color-white);
}

input {
    border: none;
    overflow: none;
}

textarea {
    border: none;
    overflow: none;
}

select {
    border: none;
    overflow: none;
}

input:focus {
    outline: 1px solid var(--color-blue);
}

textarea:focus {
    outline: 1px solid var(--color-blue);
}

select:focus {
    outline: 1px solid var(--color-blue);
    ;
}

.bia {
    border: dotted var(--BACKGROUNDCOLOR-BLUE);
    color: var(--color-blue);
    font-weight: bolder;
}

.a {
    box-shadow: 1px 1px 15px 1px rgb(239, 239, 239);
}


.uppercas{
    text-transform: uppercase;
}

input.uppercas::placeholder {
    text-transform: none;
  }
  
textarea.uppercas::placeholder {
    text-transform: none;
  }